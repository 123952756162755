import { Inject, Injectable, Injector } from '@angular/core';

import { Reports_FlowService } from './Reports.flow.index';
import { PrintNode_FlowService } from './PrintNode.flow.index';
import { LocusRobotics_FlowService } from './LocusRobotics.flow.index';

import { app_custom_job_prioritization_flowService } from './app.flow.index';
import { app_custom_print_mainService } from './app.flow.index';

import { $frontendTypes } from './app.frontend.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Reports = this.injector.get(Reports_FlowService);
    this.PrintNode = this.injector.get(PrintNode_FlowService);
    this.LocusRobotics = this.injector.get(LocusRobotics_FlowService);
  }

    public Reports: Reports_FlowService;
    public PrintNode: PrintNode_FlowService;
    public LocusRobotics: LocusRobotics_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
 
  private _custom_job_prioritization_flow: app_custom_job_prioritization_flowService;
  public async custom_job_prioritization_flow(inParams: { orderId?: number }): Promise< { jobPriority?: number }> {
    if(!this._custom_job_prioritization_flow) {
      this._custom_job_prioritization_flow = this.injector.get(app_custom_job_prioritization_flowService);
    }
    return this._custom_job_prioritization_flow.run(inParams);
  }
   
   

   
 
  private _custom_print_main: app_custom_print_mainService;
  public async custom_print_main(inParams: { content?: number, logId?: string, transactionId?: string }): Promise< { base64LabelData?: string }> {
    if(!this._custom_print_main) {
      this._custom_print_main = this.injector.get(app_custom_print_mainService);
    }
    return this._custom_print_main.run(inParams);
  }
   
   

   
}
