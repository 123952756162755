import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_cycle_count_report_detailsService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { cycle_count_id: number, operation_code_id: number, chain_head?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string, Status?: { Name?: string } }, Material?: { Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string }, MaterialGroup?: { Name?: string } }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, recount?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.cycle_count_id)) {
      missingRequiredInParams.push('\'cycle_count_id\'');
    }
    if (isNil(inParams.operation_code_id)) {
      missingRequiredInParams.push('\'operation_code_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { cycle_count_id: number, operation_code_id: number, chain_head?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string, Status?: { Name?: string } }, Material?: { Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string }, MaterialGroup?: { Name?: string } }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, recount?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.cycle_count_id)) {
      missingRequiredInParams.push('\'cycle_count_id\'');
    }
    if (isNil(inParams.operation_code_id)) {
      missingRequiredInParams.push('\'operation_code_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { cycle_count_id: number, operation_code_id: number, chain_head?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string, Status?: { Name?: string } }, Material?: { Description?: string, LookupCode?: string, Project?: { LookupCode?: string, Name?: string }, MaterialGroup?: { Name?: string } }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, VendorLot?: { LookupCode?: string } }, recount?: { Id?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedSourceLocation?: { Name?: string }, ExpectedSourceLicensePlate?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, InventoryCountTaskProperty?: { TaskId?: number, InventoryCount?: { Id?: number, Description?: string, LookupCode?: string, Status?: { Name?: string } } }, Status?: { Name?: string } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.cycle_count_id)) {
      missingRequiredInParams.push('\'cycle_count_id\'');
    }
    if (isNil(inParams.operation_code_id)) {
      missingRequiredInParams.push('\'operation_code_id\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
