import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $frontendTypes } from './LocusRobotics.frontend.types'

@Injectable({ providedIn: 'root' })
export class LocusRobotics_orderJob_statusService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { OrderJobResult: { EventType?: string, JobId: string, JobStatus?: string, JobDate?: string, RequestId?: string, EventInfo?: string }, logId?: string, transactionId?: string }): Promise<void> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.OrderJobResult)) {
      missingRequiredInParams.push('\'OrderJobResult\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

