import { Inject, Injectable, Injector }from '@angular/core';

import { Reports_DatasourceService } from './Reports.datasource.index';
import { PrintNode_DatasourceService } from './PrintNode.datasource.index';
import { LocusRobotics_DatasourceService } from './LocusRobotics.datasource.index';

import { app_custom_ds_get_job_prioritizationService } from './app.datasource.index';

@Injectable({ providedIn: 'root' })
export class app_DatasourceService {

  constructor(
    private injector: Injector
  ) {
    this.Reports = this.injector.get(Reports_DatasourceService);
    this.PrintNode = this.injector.get(PrintNode_DatasourceService);
    this.LocusRobotics = this.injector.get(LocusRobotics_DatasourceService);
  }

    public Reports: Reports_DatasourceService;
    public PrintNode: PrintNode_DatasourceService;
    public LocusRobotics: LocusRobotics_DatasourceService;
  public app: app_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_ds_get_job_prioritization: app_custom_ds_get_job_prioritizationService;
  public get custom_ds_get_job_prioritization(): app_custom_ds_get_job_prioritizationService {
    if(!this._custom_ds_get_job_prioritization) {
      this._custom_ds_get_job_prioritization = this.injector.get(app_custom_ds_get_job_prioritizationService);
    }
    return this._custom_ds_get_job_prioritization;
  }
}

