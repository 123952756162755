import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ds_get_serial_number_by_lookupService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { lookup: string, material: string, projectId: number }): 
  Promise<{ result: { Id?: number, LookupCode?: string, PackagingId?: number, Lot?: { Id?: number, Material?: { Id?: number, LookupCode?: string } }, LicensePlate?: { Id?: number, LookupCode?: string, Location?: { Id?: number } } } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.lookup)) {
      missingRequiredInParams.push('\'lookup\'');
    }
    if (isNil(inParams.material)) {
      missingRequiredInParams.push('\'material\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
