import { Inject, Injectable, Injector } from '@angular/core';


import { LocusRobotics_outbound_labelsService } from './LocusRobotics.report.index';

@Injectable({ providedIn: 'root' })
export class LocusRobotics_ReportService {

  constructor(
    private injector: Injector
  ) {
  }

  public LocusRobotics: LocusRobotics_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _outbound_labels: LocusRobotics_outbound_labelsService;
  public get outbound_labels(): LocusRobotics_outbound_labelsService {
    if(!this._outbound_labels) {
      this._outbound_labels = this.injector.get(LocusRobotics_outbound_labelsService);
    }
    return this._outbound_labels;
  }
}

