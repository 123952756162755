import { Inject, Injectable, Injector } from '@angular/core';

import { Reports_ReportService } from './Reports.report.index';
import { PrintNode_ReportService } from './PrintNode.report.index';
import { LocusRobotics_ReportService } from './LocusRobotics.report.index';


@Injectable({ providedIn: 'root' })
export class app_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Reports = this.injector.get(Reports_ReportService);
    this.PrintNode = this.injector.get(PrintNode_ReportService);
    this.LocusRobotics = this.injector.get(LocusRobotics_ReportService);
  }
    public Reports: Reports_ReportService;
    public PrintNode: PrintNode_ReportService;
    public LocusRobotics: LocusRobotics_ReportService;

  public app: app_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
}

