import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { LocusRobotics_homeComponent } from './LocusRobotics.home.component';
import { LocusRobotics_outbound_labelsComponent } from './LocusRobotics.outbound_labels.component';


@Injectable({ providedIn: 'root' })
export class LocusRobotics_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
  ) {
    super(dialog, toastr);
  }

  public LocusRobotics: LocusRobotics_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openhome(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'LocusRobotics',
        referenceName: 'LocusRobotics_home',
        component: LocusRobotics_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openhomeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      LocusRobotics_homeComponent,
      'LocusRobotics',
      mode,
      dialogSize
    )
  }
  public openoutbound_labels(inParams:{ shippingContainerId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'outbound_labels',
        referenceName: 'LocusRobotics_outbound_labels',
        component: LocusRobotics_outbound_labelsComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openoutbound_labelsDialog(
    inParams:{ shippingContainerId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      LocusRobotics_outbound_labelsComponent,
      'outbound_labels',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'LocusRobotics_home') {
      const title = 'LocusRobotics';
      const component = LocusRobotics_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'LocusRobotics_outbound_labels') {
      const title = 'outbound_labels';
      const component = LocusRobotics_outbound_labelsComponent;
      const inParams:{ shippingContainerId: number } = { shippingContainerId: null };
      if (!isNil(params.get('shippingContainerId'))) {
        const paramValueString = params.get('shippingContainerId');
        inParams.shippingContainerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    return result;
  }
}
