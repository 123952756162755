import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_outbound_packing_slip_get_project_address_by_projectIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId: number }): 
  Promise<{ result: { ProjectId?: number, ContactId?: number, Contact?: { Id?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { projectId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ProjectId?: number, ContactId?: number, Contact?: { Id?: number, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, Line3?: string, PostalCode?: string, State?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
